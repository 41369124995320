import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import usdtImg from "assets/images/usdtImg.png";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import { throttle } from "lodash";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [balance, setBalance] = useState<any>();
  const [amount, setAmount] = useState<any>(0);
  const [previewWithdraw, setPreviewWithdraw] = useState<any>();
  const [password, setPassword] = useState<any>();
  const passwordRef = useRef("")
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);
  const getBalance = async () => {
    try {
      const parmas = { account: email || address, token: token };
      const res = await request({
        method: "POST",
        url: "/api/index/walletPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setBalance(res.data.balance2);
    } catch (error) {}
  };

  const getPreviewWithdraw = async () => {
    try {
      const parmas = {
        account: email || address,
        amount: amount,
        token: token,
      };
      const res = await request({
        method: "POST",
        url: "/api/index/previewWithdraw",
        params: { lang: i18n.language },
        data: parmas,
      });
      setPreviewWithdraw(res.data);
    } catch (error) {}
  };


  const handleConfirmClick = async (v: any) => {
    // 在点击按钮时获取最新的 password 值
    const updatedPassword = passwordRef.current;

    console.log(updatedPassword); // 这里可以正常打印最新的 password

    const params = {
      account: email || address,
      amount: v?.amount,
      toaccount: v?.toaccount,
      token: token,
      password: updatedPassword, // 使用最新的 password 值
    };

    try {
      const res: any = await request({
        method: "POST",
        url: "/api/users/withdraw",
        params: { lang: i18n.language },
        data: params,
      });
      Toast.show(res.msg);
    } catch (error) {
      // 处理错误
    }
  };

  const onFinish = async (v: any) => {
    try {
      if (!v?.amount) {
        Toast.show(t("請輸入提現金額"));
        return;
      }
      Modal.show({
        title: t("輸入密碼"),
        content: (
          <div className="withdrawInput__box">
            <Input value={password} onChange={val => {
            setPassword(val)
            passwordRef.current = val;
          }} />
            <Button
              style={{
                width: '80%',
                margin: '20px auto',
                "--background-color": "rgb(36, 105, 82)",
                "--text-color": "#fff",
                "--border-radius": "40px",
              }}
              block
              size="large"
              onClick={() => handleConfirmClick(v)}
            >
              {t("確定")}
            </Button>
          </div>
        ),
        closeOnMaskClick: true,
      });
    } catch (error) {}
  };

  const ThrottledOnClickNodeBuy = throttle(onFinish, 3000, {
    leading: true,
    trailing: false,
  });

  useEffect(() => {
    getBalance();
    form.setFieldValue("amount", 0);
  }, []);

  useEffect(() => {
    getPreviewWithdraw();
  }, [amount]);
  useEffect(() => {
    console.log(password)
  }, [password]);

  return (
    <>
      <div className="OnlineWithdraw">
        <Header title={t("鏈上提幣")} />
        <div className="OnlineWithdraw__box">
          <div className="list__left">
            <Image src={usdtImg} width={40} height={40} />{" "}
            <div className="flex__space__between">
              <div className="flex__direction">
                <span className="USDT">USDT</span> <span>Tether</span>
              </div>
            </div>
          </div>
          {/* <div className="card__box">
            <div className="title__font">{t("選擇主網")}</div>
            <div className="card__box__input cardBG">USDT-BEP20</div>
          </div> */}
          <div className="card__box">
            <div className="card__box__input">
              <Form
                form={form}
                onFinish={ThrottledOnClickNodeBuy}
                onValuesChange={(v, a) => {
                  setAmount(a?.amount || amount);
                }}
                footer={
                  <>
                    {/*<div className="Withdraw__message">*/}
                    {/*  <div className="Withdrawnum">*/}
                    {/*    {t("到帳數量")}: {previewWithdraw?.newamount}USDT{" "}*/}
                    {/*  </div>*/}
                    {/*  <div className="Withdrawfee">*/}
                    {/*    {t("網絡手續費")}: {previewWithdraw?.fee}USDT*/}
                    {/*  </div>*/}
                    {/*  <div className="Withdrawfee">*/}
                    {/*    {t("手續費費率")}: {previewWithdraw?.rate}{" "}*/}
                    {/*    /!*<span className="yellow-color ml8">*!/*/}
                    {/*    /!*  {t("(周日免手續費)")}*!/*/}
                    {/*    /!*</span>*!/*/}
                    {/*  </div>*/}
                    {/*  <div className="Withdrawfee">*/}
                    {/*    <span className="yellow-color ml8">*/}
                    {/*      {previewWithdraw?.withdrawalday}*/}
                    {/*    </span>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="Withdraw__message">
                      {/*<div className="Withdrawnum">*/}
                      {/*  {t("到帳數量")}: {previewWithdraw?.newamount}USDT{" "}*/}
                      {/*</div>*/}
                      {/*<div className="Withdrawfee">*/}
                      {/*  {t("網絡手續費")}: {previewWithdraw?.fee}USDT*/}
                      {/*</div>*/}
                      {/*<div className="Withdrawfee">*/}
                      {/*  {t("手續費費率")}: {previewWithdraw?.rate}{" "}*/}
                      {/*  /!*<span className="yellow-color ml8">*!/*/}
                      {/*  /!*  {t("(周日免手續費)")}*!/*/}
                      {/*  /!*</span>*!/*/}
                      {/*</div>*/}

                      {/*<div className="Withdrawfee">*/}
                      {/*  <span className="yellow-color ml8">*/}
                      {/*    {previewWithdraw?.withdrawalday}*/}
                      {/*  </span>*/}
                      {/*</div>*/}

                    </div>

                    <Button
                      style={{
                        "--background-color": "rgb(36, 105, 82)",
                        "--border-radius": "40px",
                        "--text-color": "#fff",
                      }}
                      block
                      type="submit"
                      size="large"
                    >
                      {t("提現")}
                    </Button>
                  </>
                }
                layout="horizontal"
              >
                <div className="title__font">{t("提現地址")}</div>
                <Form.Item
                  name={"toaccount"}
                  extra={<div className={"form__USDT"}></div>}
                >
                  <Input placeholder="" />
                </Form.Item>
                <div className="title__font">
                  {t("最低提現")}
                  {previewWithdraw?.minimum}U
                </div>
                <div className="title__font">{t("提現金額")}</div>
                <Form.Item
                  name={"amount"}
                  extra={
                    <div className={"form__USDT"}>
                      {" "}
                      <span>USDT</span>{" "}
                      <span
                        onClick={() => {
                          form.setFieldValue("amount", balance);
                          setAmount(balance);
                        }}
                        className="allWithdraw"
                      >
                        {t("全部提現")}
                      </span>
                    </div>
                  }
                >
                  <Input />
                </Form.Item>
                <div className="title__font">
                  {t("可用")}：{balance}USDT
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
