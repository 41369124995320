import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Popover,
  Toast,
  Popup,
  Swiper,
  Image,
  Modal,
  Slider,
  FloatingBubble,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "../../utils/getContract";
import { config } from "../../config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import {
  addressSub,
  localSetItem,
  modalComponents,
  modalDefiComponents,
} from "utils/tools";
import request from "utils/request";
import { trim } from "../../utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { TextOutline } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "../../utils/web3React";
import withRouter from "../../components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import logo from "assets/images/logo.png";
import lan from "assets/images/lan.png";
import menu from "assets/images/menu.png";
import swiper1 from "assets/images/swiper1.png";
import swiper2 from "assets/images/swiper2.png";
import swiper3 from "assets/images/swiper3.png";
import invite from "assets/images/invite.png";
import fundDetails from "assets/images/fundDetails.png";
import record from "assets/images/record.png";
import WrapTitle from "pages/Home/WrapTitle";
import email from "assets/images/email.png";
import tglogo from "assets/images/tglogo.png";
import lotteryIcon from "assets/images/lotteryIcon.png";
import twitter from "assets/images/twitter.png";
import midBottom from "assets/images/midBottom.png";
import aboutUs from "assets/images/logo.png";
import markIcon from "assets/images/markIcon.png";
import usdtImg from "assets/images/usdtImg.png";
import bitIcon from "assets/images/bitIcon.png";
import bitIcon1 from "assets/images/bitIcon1.png";
import bitIcon2 from "assets/images/bitIcon2.png";
import onePolyline from "assets/images/onePolyline.png";
import twoPolyline from "assets/images/twoPolyline.png";
import threePolyline from "assets/images/threePolyline.png";
// import logo from "assets/images";
import "./index.scss";
import { url } from "inspector";
import EchartsLine from "components/EchartsLine";
import useNavitateLink from "utils/useNavitateLink";
import dayjs from "dayjs";
const Index: React.FC = (props: any) => {
  const goToPage = useNavitateLink();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [menuVisible, setMenuVisible] = useState(false);
  const [announcementList, setAnnouncementList] = useState<any>([]); // 所有公告
  const [announcement, setAnnouncement] = useState<any>([]); // 当前语言公告
  const [mainPage, setMainPage] = useState<any>();

  const [powerList, setPowerList] = useState<any>([]);
  const [powerIndex, setPowerIndex] = useState<any>(0);
  const [powerproducts, setPowerproducts] = useState<any>();

  const [sliderValue, setSliderValue] = useState<any>();
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();
  const webParams = useParams();
  const location = useLocation();

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const bgimages = [
    {
      img: swiper1,
      font1: t("數字資產綜合金融服務提供商"),
      font2: t("一鍵開啟高效收益"),
      font3: t("了解更多"),
      // link: "https://bitcontinent.net/",
      link: "https://bitcontinent.top/",
      color: "rgb(177, 76, 16)",
      onClick: function a() {},
    },
    {
      img: swiper2,
      font1: t("一站式便捷算力租賃"),
      font2: t("獲取豐厚挖礦收益"),
      font3: t("立即參與"),
      link: "#linkCloudComputed",
      color: "rgb(35, 52, 203)",
      onClick: function a() {},
    },
    {
      img: swiper3,
      font1: t("賺取理財超額收益"),
      font2: t("參與鏈上火爆項目"),
      font3: t("立即參與"),
      link: "#linkDefi",
      color: "rgb(52, 13, 161)",
      onClick: function a() {},
    },
  ];

  const gotoId = (link: any) => {
    // if (link === "https://bitcontinent.net/") {
    if (link === "https://bitcontinent.top/") {
      window.open(link);
      return;
    }
  };

  const items = bgimages.map((item, index) => (
    <Swiper.Item key={index}>
      <div
        className="Swiper__box"
        style={{ backgroundImage: `url(${item.img})`, color: item.color }}
      >
        <div className="fontW700">{item.font1}</div>
        <div className="fontS14">{item.font2}</div>
        <a
          // href={item.link === "https://bitcontinent.net/" ? "" : item.link}
          href={item.link === "https://bitcontinent.top/" ? "" : item.link}
          onClick={() => gotoId(item.link)}
          className="btn__box"
          style={{ backgroundColor: item.color }}
        >
          {item.font3}
        </a>
      </div>
    </Swiper.Item>
  ));

  const DefiFont1 = {
    a: t(
      "Lowest risk and lowest APR for the risk averse Level LPs. In case of unexpected shortfalls resulting from adverse market conditions or other incidents, the Senior Tranche bears the lowest risk but also earns the smallest share of platform profits"
    ),
    b: t("Has been running for over a year"),
    c: t(
      "Security is priority number one. DeFi can be an intimidating place, especially at a time when exploits and hacks are rampant. For market participants, this leads to uncertainty and a general lack of confidence across the industry. To mitigate this impact, the team is focused on acting with integrity and rigorous oversight across the LEVEL ecosystem whilst we pave our way toward trustless decentralization. We believe this ensures all stakeholders can participate at LEVEL with peace of mind in the system as a whole. We are not here to sell you a product, we are here to build a community."
    ),
    d: t("https://obeliskauditing.com/audits/level-finance-core"),
  };

  const changePowerIndex = (direction: string) => {
    const powerLength = powerList.length - 1;
    if (powerIndex === 0 && direction === "left") {
      setPowerIndex(powerLength);
      return;
    }
    if (powerIndex === powerLength && direction === "right") {
      setPowerIndex(0);
      return;
    }
    if (direction === "left") {
      setPowerIndex(powerIndex - 1);
      return;
    }
    if (direction === "right") {
      setPowerIndex(powerIndex + 1);
      return;
    }
  };

  // 首页/api/index/mainPage

  const getMainPage = async () => {
    try {
      const token = localStorage.getItem('token');
      const email = localStorage.getItem('email');
      const address = localStorage.getItem("address");
      const parmas = { account: email || address, token: token };
      let params
      if (parmas.account === ''){
        params = { lang: i18n.language }
      }else{
        params = { lang: i18n.language,account:parmas.account }
      }

      const res = await request({
        method: "GET",
        url: "/api/index/mainPage",
        params: params,
      });

      setMainPage(res.data);
      localSetItem("nodesellstatus", res.data.nodesellstatus);
      setPowerList(res.data.powerproducts);

    } catch (error) {}
  };

  // 获取公告
  const getAnnouncement = async () => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    const address = localStorage.getItem("address");
    const parmas = { account: email || address, token: token };
    let params
    if (parmas.account === ''){
      params = { lang: i18n.language }
    }else{
      params = { lang: i18n.language,account:parmas.account }
    }
    const res = await request({
      method: "GET",
      url: "/api/index/getnotices",
      params:params
    });
    setAnnouncementList(res.data);
  };
  const getAccountStatus = async () => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    const address = localStorage.getItem("address");
    const parmas = { account: email || address, token: token };
    const res: any = await request({
      method: "POST",
      url: "/api/users/getAccountStatus",
      params: { lang: i18n.language },
      data: parmas,
    });
    if (res.code === 1 && res.data === '0') {
        Modal.clear();
      goToPage("/fanzha")
    }
  };

  const changeAnnouncement = () => {
    if (announcementList?.length <= 0) {
      return;
    }

    const list = [...announcementList];

    const newData = list
      .map((item: any) => {
        const message =
          i18n.language === "tc" ? item.message.tc : item.message.en;
        if (message.text !== "") {
          return {
            title: message.title,
            text: message.text,
            createtime: message.createtime,
          };
        } else {
          return null; // 如果 message.text 为空，则返回 null
        }
      })
      .filter((item: any) => item !== null);
    setAnnouncement(newData);
  };

  const modalContent = (
    <>
      <div>
        <div
          onClick={() => {
            goToPage("/bindEmail");
            Modal.clear();
          }}
          className="modal__ok"
        >
          {t("綁定Email")}
        </div>
      </div>
    </>
  );

  const bindUser = async () => {
    try {
      let referad = "";
      const queryString = location.search;
      const match = /invitationCode=([^&]+)/.exec(queryString);
      if (match) {
        referad = match[1];
      } else {
        console.log("invitationCode 未找到");
      }
      const parmas = { account: account, referad: referad };
      const res: any = await request({
        method: "POST",
        url: "/api/users/bind",
        params: { lang: i18n.language },
        data: parmas,
      });
      if (res.code === 0) {
        Toast.show(res.msg);
        logout();
        return;
      }
      if (res.code === 1) {
        const parmas = { address: account };
        const res: any = await request({
          method: "POST",
          url: "/api/users/checkAddress",
          params: { lang: i18n.language },
          data: parmas,
        });
        console.log(res);
        if (res.code === 0) {
          Modal.show({
            title: t("請綁定Email"),
            content: modalContent,
            closeOnMaskClick: false,
          });
        }
        if (res.code === 1) {
          localSetItem("token", res.data);
          account && localSetItem("address", account);
        }

        // 调用查看有没邮箱  如果没有邮箱 弹窗 去注册
        //  如果有邮箱 获取token 存在local

        return;
      }
    } catch (error) {}
  };

  const announcementModalContent = (
    <>
      <div style={{
        maxHeight: '300px',
        overflowY: 'scroll'
      }}>
      <div className="break__spaces">{announcement?.[0]?.text}</div>
      <p>{dayjs.unix(announcement?.[0]?.createtime).format("YYYY-MM-DD")}</p>
      </div>
      <div
          onClick={() => {
            Modal.clear();
          }}
          className="modal__ok"
        >
          {t('確定')}
        </div>
    </>
  );

  const announcementModal = () => {
    announcement.length > 0 && announcementList[0].ad1status==='1' &&
      Modal.show({
        title: announcement[0].title,
        closeOnMaskClick: true,
        content: announcementModalContent,
        // showCloseButton: true,
      });
  };

  // /api/users/checkEmailAndAddress

  // onClick={() => modalComponents(t(""),t(""))}

  useEffect(() => {
    setPowerproducts(powerList[powerIndex]);
  }, [powerList, powerIndex]);
  useEffect(() => {
    setSliderValue(Math.floor((powerproducts?.Day?.length - 1) / 2));
  }, [powerproducts]);

  useEffect(() => {
    getMainPage();
    getAnnouncement();
  }, [i18n.language]);

  useEffect(() => {
    i18n.on("languageChanged", changeAnnouncement);
    changeAnnouncement();
  }, [announcementList]);

  useEffect(() => {
    if (active) {
      getAccountStatus();
      announcementModal();
      const nowEmail = localStorage.getItem("email");
      if (!nowEmail) {
        bindUser();
      }
      return;
    }
    logout();
  }, [account]);
  useEffect(() => {
    const hasToken = localStorage.getItem("token") || false;
    hasToken && getAccountStatus();
  }, [])

  useEffect(() => {
    if (announcement.length > 0) {
      const hasToken = localStorage.getItem("token") || false;
      hasToken && announcementModal();
    }
  }, [announcement]);

  return (
    <>
      <div className="Home">
        <Header nodeChange={mainPage?.nodesellstatus}/>
        <div className="Mid">
          <Swiper
            style={{
              "--track-padding": " 0 0 16px",
            }}
            autoplay
            indicatorProps={{ className: "Swiper__indicator" }}
          >
            {items}
          </Swiper>
          <div className="Swiper__bottom">
            <div
              onClick={() => goToPage("/inviteFriend")}
              className="Swiper__bottom__action"
            >
              <Image src={invite} width={24} />
              <div className="Swiper__bottom__font text__center">
                {t("邀請好友")}
              </div>
            </div>
            <div
              onClick={() => goToPage("/orderDetail")}
              className="Swiper__bottom__action"
            >
              <TextOutline color="#fff" width={24} height={24} />
              <div className="Swiper__bottom__font text__center">
                {t("資金明細")}
              </div>
            </div>
            <div
              onClick={() => goToPage("/wallet")}
              className="Swiper__bottom__action"
            >
              <Image src={fundDetails} width={24} />
              <div className="Swiper__bottom__font text__center">
                {t("訂單明細")}
              </div>
            </div>
            <div
              onClick={() => goToPage("/actionRecord/0")}
              className="Swiper__bottom__action"
            >
              <Image src={record} width={24} />
              <div className="Swiper__bottom__font text__center">
                {t("操作記錄")}
              </div>
            </div>
          </div>
        </div>
        <div className="Bottom">
          <WrapTitle
            id="linkCloudComputed"
            title={t("算力收益")}
            url={"/cloudComputed"}
          >
            <div className="Bottom__box">
              <div
                onClick={() => changePowerIndex("left")}
                className="leftEchartsLine"
              >
                {"<"}
              </div>
              <div
                onClick={() => changePowerIndex("right")}
                className="rightEchartsLine"
              >
                {">"}
              </div>
              <div className="Bottom__title flex__space__between">
                <div>
                  <span>{powerproducts?.name}</span>{" "}
                  {/* <span className="yellow-color">{powerproducts?.damagerate}</span>{" "} */}
                </div>
                <div className="flex__space__between">
                  <span>{t("預估日化")}：</span>{" "}
                  <span className="green2-color">{powerproducts?.dayrate}</span>
                  <Image
                    onClick={() =>
                      modalComponents(
                        t("提示"),
                        t(
                          "當前殖利率依過往30日比特幣的價格、挖礦收益進行預估，不作為實際收益保障"
                        )
                      )
                    }
                    src={markIcon}
                    width={20}
                    height={20}
                  />{" "}
                </div>
              </div>
              {powerproducts && (
                <EchartsLine
                  powerproducts={powerproducts}
                  sliderValue={sliderValue}
                />
              )}
              <Slider
                // defaultValue={40}
                className="Slider__name"
                onChange={setSliderValue}
                min={0}
                max={powerproducts?.Day?.length - 1 || 0}
                value={sliderValue}
                // onAfterChange={toastValue}
                icon={<span className="Slider__icon"></span>}
              />
              <div className="Bottom__box__title">
                {powerproducts?.cost || ""}USDT{t("算力租賃")}
                {powerproducts?.cycle || ""}
                {t("天收益報告")}
              </div>
              <div>
                <span className="Bottom__tit">
                  {powerproducts?.Day[sliderValue]}
                  {t("天算力收益")}：
                </span>
                <span className="Bottom__usdt">
                  {powerproducts?.Balance[sliderValue]}USDT
                </span>
              </div>
            </div>
          </WrapTitle>

          <WrapTitle id="linkDefi" title={t("託管")} url="/defi">
            {mainPage?.defiproductsdata.length > 0 &&
              mainPage?.defiproductsdata.map((item: any, index: number) => {
                return (
                  <div key={index} className="card__box padding20">
                    <div className="Defi__box flex__space__between">
                      <div className="Defi__box__Polyline flex__direction">
                        <div className="flex__space__between">
                          <div className="Defi__box__Polyline__Img">
                            <Image
                              className="Defi__box__Polyline__Img0"
                              src={usdtImg}
                              height={32}
                              width={32}
                            />
                            <Image
                              className="Defi__box__Polyline__Img1"
                              src={bitIcon}
                              height={20}
                              width={20}
                            />
                            <Image
                              className="Defi__box__Polyline__Img2"
                              src={bitIcon1}
                              height={20}
                              width={20}
                            />
                            <Image
                              className="Defi__box__Polyline__Img3"
                              src={bitIcon2}
                              height={20}
                              width={20}
                            />
                          </div>
                          <div className="flex__direction">
                            <span className="Defi__box__title text__over">
                              {item.name}
                            </span>
                            <div className="Defi__box__key">
                              {t("限時發布")}
                              <Image
                                onClick={() => modalDefiComponents(DefiFont1)}
                                src={markIcon}
                                width={20}
                                height={20}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <Image src={onePolyline} height={41} width={160} />{" "}
                        </div>
                      </div>
                      <div className="flex__direction">
                        <div className="Defi__APY">
                          <span className="Defi__APY__key text__right">
                            {item.interestrate}
                          </span>{" "}
                          <span className="Defi__APY__value text__right">
                            {t("收益")}
                          </span>
                        </div>
                        {/* <div className="Defi__TVL">周期 :{item.cycle}</div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
          </WrapTitle>

          <WrapTitle title={t("AI複合交易")} url="/manageMoney">
            <div className="card__box padding20">
              <div className="AI__box">
                <Image src={usdtImg} width={32} height={32} />{" "}
                <span className="AI__title">USDT {t("複合套利")}</span>{" "}
                <Image
                  onClick={() =>
                    modalComponents(
                      t("產品說明"),
                      t(
                        "複合套利策略包含跨平臺價差回歸套利、跨期合約交割套利、期現價差套利和資金費率套利等多個部分，利用同交易對不同交易平臺之間的敞口機會、交割合約的跨期限交易/期現盤口價差波動和費率體系等系統性機會進行安全套利，不受大盤漲跌影響。 為用戶帶來穩定的理財收益。"
                      )
                    )
                  }
                  src={markIcon}
                  width={20}
                  height={20}
                />
              </div>
              <div className="AI__key">
                <div className="AI__key__name">
                  <span>{t("日化報酬率")}</span>
                  <Image
                    onClick={() =>
                      modalComponents(
                        t("收益規則"),
                        t("殖利率根據市場行情會有所波動不作為實際收益保障")
                      )
                    }
                    src={markIcon}
                    width={20}
                    height={20}
                  />{" "}
                </div>
                <div className="AI__key__value">{mainPage?.aidayrate}</div>
              </div>
            </div>
          </WrapTitle>

          <WrapTitle title={t("關於我們")} noDetail={true}>
            <div onClick={() => goToPage("/aboutUs")} className="aboutUs">
              {/* <Image className="aboutUs__logo" src={aboutUs} height={140} />{" "} */}
            </div>
          </WrapTitle>

          <WrapTitle title={t("公告中心")} noDetail={true}>
            <div className="card__box announcement">
              <div>
                {announcement?.length > 0 &&
                  announcement.map((item: any, index: any) => {
                    return (
                      <div
                        onClick={() => {
                          goToPage("/announcement/detail", { state: item });
                        }}
                        key={index}
                        className="underline whitespace__nowrap announcement__box"
                      >
                        {index + 1 + "." + item.title}
                      </div>
                    );
                  })}
              </div>
              <Image
                onClick={() => goToPage("/announcement")}
                className="goAnnouncement__detail"
                src={midBottom}
                width={22}
                height={13}
              />
            </div>
          </WrapTitle>

          <div className="card__box padding20">
            <a className="link__a">
              {" "}
              {/* <Image src={tglogo} width={20} height={20} />{" "} */}
              <span className="font__bold">{mainPage?.customername1}</span>
            </a>
            <a className="link__a">
              {" "}
              {/* <Image src={tglogo} width={20} height={20} />{" "} */}
              <span className="font__bold">{t("客服帳號")}：</span>
              <span>{mainPage?.customeraccount1}</span>
            </a>
            <a className="link__a">
              {" "}
              {/* <Image src={tglogo} width={20} height={20} />{" "} */}
              <span className="font__bold">{mainPage?.customername2}</span>
            </a>
            <a className="link__a">
              {" "}
              {/* <Image src={tglogo} width={20} height={20} />{" "} */}
              <span className="font__bold">{t("客服帳號")}：</span>
              <span>{mainPage?.customeraccount2}</span>
            </a>
          </div>
        </div>
        <FloatingBubble
          axis="xy"
          magnetic="x"
          style={{
            "--initial-position-bottom": "24px",
            "--initial-position-right": "24px",
            "--edge-distance": "24px",
          }}
          onClick={() => goToPage("/lottery")}
        >
          <Image src={lotteryIcon} width={50} />
        </FloatingBubble>
      </div>
    </>
  );
};
export default Index;
