import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import usdtImg from "assets/images/usdtImg.png"
import { SearchOutline } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import useNavitateLink from "utils/useNavitateLink";
import dayjs from "dayjs";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const goToPage = useNavitateLink();
  const { active, account, activate, deactivate } = useWeb3React();
  const [balance, setBalance] = useState<any>();
  const [ operationPage,setOperationPage] = useState<any>();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const getBalance = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/walletPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setBalance(res.data.balance2);
    } catch (error) {}
  };

  const getOperationPage = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/operationPage",
        params: {lang: i18n.language},
        data: parmas,
      });
      setOperationPage(res.data);
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };

  const modalContent = <>
    <div>
      <div onClick={() => {Modal.clear(); goToPage('/onlineWithdraw')}} className="modal__linkWithdraw">{t('鏈上提幣')}</div>
      {/* <div onClick={() => {Modal.clear(); goToPage('/WithdrawAddress')}} className="modal__urlWithdraw">地址充值</div> */}
    </div>
  </>

function Withdrawdata() {
  return <div style={{overflowX: 'scroll'}}><table className="tableList__Box">
  <thead>
    <tr className="">
      <th>{t('時間')}</th>
      <th>{t('金額')}</th>
      <th>{t('狀態')}</th>
    </tr>
  </thead>
  <tbody>
    {operationPage?.withdrawdata?.length > 0 &&
      operationPage?.withdrawdata?.map((item: any, index: number) => {
        return (
          <tr key={index} className="">
            <th>{dayjs.unix(item.createtime).format('YYYY-MM-DD')}</th>
            <th>{item.amount}USDT</th>
            <th>{item.status === '0' ? t('處理中') : item.status === '1' ? t('提現成功') : t('提現駁回')}</th>
          </tr>
        );
      })}
  </tbody>
</table></div>
}

  useEffect(() => {
    getBalance();
    getOperationPage();
  }, []);

  return (
    <>
      <div className="Withdraw">
        <Header title={t('提現')} />
        <div className="searchBox">
          <div className="searchIcon">
            <span className="searchIcon__span"><SearchOutline fontSize={20} /> </span>
            <input placeholder={t("請輸入幣種名稱")} className="searchInput" type="text" />
          </div>
        </div>
        <div className="WalletNet__bot">
          <div onClick={() => {
              Modal.show({
                title: t('提幣類型'),
                content: modalContent,
                closeOnMaskClick: true,
              })
            }} className="list__usdt">
              <div className="list__left">
                <Image src={usdtImg} width={40} height={40} /> <div className="flex__direction"><span className="USDT">USDT</span> <span>Tether</span></div> 
              </div>
              <div className="list__right">
                <div>{balance}</div>
                <span>$ {balance}</span>
              </div>
          </div>
          <Withdrawdata />
        </div>
      </div>
    </>
  );
};
export default Index;
