import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Popover,
  Popup,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub, localSetItem } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { CollectMoneyOutline } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import logo from "assets/images/logo.png";
import bitmainLogo from "assets/images/bitmainLogo.png";
import lan from "assets/images/lan.png";
import menu from "assets/images/menu.png";
import menuHome from "assets/images/menuHome.png";
import menuPower from "assets/images/menuPower.png";
import menuDefi from "assets/images/menuDefi.png";
import menuWallet from "assets/images/menuWallet.png";
import menuNode from "assets/images/menuNode.png";
import menuAnnouncement from "assets/images/menuAnnouncement.png";
import menuAi from "assets/images/menuAi.png";
import menuWebsite from "assets/images/menuWebsite.png";
import changePassword from "assets/images/changePassword.png";
import copy from "copy-to-clipboard";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import request from "utils/request";
import useNavitateLink from "utils/useNavitateLink";
interface HeaderProps {
  title?: string;
  hasLogin?: boolean;
  nodeChange?: string;
}
const Index: React.FC<HeaderProps> = ({ title, hasLogin = true ,nodeChange}) => {
  const goToPage = useNavitateLink();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [menuVisible, setMenuVisible] = useState(false);
  const [isShow, setIsShow] = useState(true);
  const [loginText, setLoginText] = useState(localStorage.getItem('token') ? t('退出') : t('登入'));
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [nowPathname, setNowPathname] = useState("/");
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const location = useLocation();

  const logout = useCallback( async() => {
    // /api/users/logout
    const parmas = { token: localStorage.getItem('token'), email: localStorage.getItem('email') || localStorage.getItem('address') };
      const res: any = await request({
        method: "POST",
        url: "/api/users/logout",
        params: { lang: i18n.language },
        data: parmas,
      });
      localSetItem('token')
      localSetItem('email')
      localSetItem('address')
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const lanList = [
    { key: "en", text: "English" },
    { key: "tc", text: "繁体中文" },
  ];

  const menuList = [
    {
      key: "/",
      label: t('首頁'),
      img: menuHome,
      show: true
    },
    {
      key: "/cloudComputed",
      label: t('算力'),
      img: menuPower,
      show: true
    },
    {
      key: "/defi",
      label: t('託管'),
      img: menuDefi,
      show: true
    },
    {
      key: "/nodeBuy",
      label: t('節點搶購'),
      img: menuNode,
      show: isShow
    },
    {
      key: "/manageMoney",
      label: t('AI複合交易'),
      img: menuAi,
      show: true
    },
    {
      key: "/changePassword",
      label: t('修改密碼'),
      img: changePassword,
      show: true
    },
    {
      key: "/wallet",
      label: t('錢包'),
      img: menuWallet,
      show: true
    },
    {
      key: "/announcement",
      label: t('公告'),
      img: menuAnnouncement,
      show: true
    },
    {
      // key: "https://bitcontinent.net/",
      key: "https://bitcontinent.top/",
      label: t('官網'),
      img: menuWebsite,
      show: true
    },
  ];

  const clickMenu = (key: string) => {
    // if (key === 'https://bitcontinent.net/') {
    if (key === 'https://bitcontinent.top/') {
      window.open(key);
      return
    }
    setMenuVisible(false);
    goToPage(key);
  }

  const menuContent = (
    <>
      <div className="rightMenuBox">
        <div className="rightMenuBox__logo">
          <Image src={bitmainLogo} width={150} />
        </div>
        <div className="rightMenuBox__list">
          {menuList.map((item, index) => {
            return (
              <div
                onClick={() => clickMenu(item.key)}
                className="rightMenuBox__list__row"
                style={{
                  display: item.show ? 'flex' : 'none' ,
                  backgroundColor:
                    nowPathname === item.key
                      ? "rgb(242, 243, 245)"
                      : "rgb(255, 255, 255)",
                }}
                key={index}
              >
                <Image src={item.img} width={28} height={28} /> {item.label}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );


  const onChangeLan = (node: any) => {
    i18n.changeLanguage(node.key)
    localSetItem('lan', node.key)
    Toast.show(`${t('選擇了')} ${node.text}`)
  }

  const clickLogin = () => {
    const token = localStorage.getItem('token');
    console.log(token)
    if (token) {
      logout();
      localSetItem('token');
      localSetItem('email');
      localSetItem('address');
      setLoginText(t('登入'))
      goToPage('/')
      return
    }
    goToPage('/login');
  }

  const checkEmailAndAddress  = async () => {
    try {
      const parmas = { address: account, email: localStorage.getItem('email') };
      const res: any = await request({
        method: "POST",
        url: "/api/users/checkEmailAndAddress",
        params: { lang: i18n.language },
        data: parmas,
      });
      if (res.code === 0) {
        Toast.show(res.msg);
        deactivate();
        return
      }
      if (res.code === 1) {
        account && localSetItem('address', account)
      }

    } catch (error) {}
  };




  useEffect(() => {
    const nodesellstatus = localStorage.getItem('nodesellstatus');
    setIsShow(nodesellstatus === '1');
    setNowPathname(location.pathname);
  }, [nodeChange]);

  useEffect(() => {
    if (active) {
      if (email) {
        // 去checkemail
        checkEmailAndAddress();
        return
      }
      return;
    }
    // console.log('被退出')
    deactivate();
  }, [account]);


  useEffect(() => {
    // 定义监听函数
    const handleStorageChange = () => {
      // 判断 sessionStorage 中的某个值是否发生了变化
       // 打印一些信息，以便调试
       setTimeout(() => {
        setLoginText(localStorage.getItem('token') ? t('退出') : t('登入'));
        setEmail(localStorage.getItem('email'))
      }, 0);
    };
    window.addEventListener('setItemEvent', handleStorageChange);
    
      return () => {
        window.removeEventListener('setItemEvent', handleStorageChange);
      };
  }, []);


  return (
    <>
      <div className="Header">
        {title ? (
          <span className="Header__title">{title}</span>
        ) : (
          <Image src={bitmainLogo} height={40} width={99.75}/>
        )}
        <div className="Header__action">
          {/* 退出 */}
          {hasLogin && <div onClick={() => clickLogin()} className="loginBtn">{loginText}</div>}
          <WalletConnect />
          <Popover.Menu
            actions={lanList.map((action) => ({
              ...action,
            }))}
            onAction={(node) => onChangeLan(node)}
            placement="bottom-start"
            trigger="click"
          >
            <Image src={lan} width={28} />
          </Popover.Menu>
          <Image
            onClick={() => setMenuVisible(!menuVisible)}
            src={menu}
            width={28}
          />
        </div>
      </div>
      <Popup
        visible={menuVisible}
        onMaskClick={() => {
          setMenuVisible(false);
        }}
        position="right"
        bodyStyle={{ width: "60vw" }}
      >
        {menuContent}
      </Popup>
    </>
  );
};
export default Index;
